import TRTC from 'trtc-sdk-v5';
import memoize from 'lodash/memoize';
import RTCDetect from 'rtc-detect';
import { UseMobileCallOut } from '../constants/sessions';
import { getLogger } from '../common/Logger';
import i18next from '../i18n/i18next.config';
import { TcccSdk } from '../tccc';

export const detect = new RTCDetect();

export const getExtension = memoize(
  async ({ userId, sdk }: { userId: string; sdk: TcccSdk }) => {
    if (!userId) return false;
    let useExtension = true;
    try {
      const { extensionList } = await sdk.http.request('/tcccadmin/extension/getList', {
        isNeedStatus: true,
        relation: userId,
        pageNum: 0,
        pageSize: 10,
      });
      useExtension = extensionList?.[0]?.relation === userId;
    } catch (e) {
      throw e;
    }
    return useExtension;
  },
  ({ userId }) => userId,
);

export const checkDevice = async (sdk: TcccSdk) => {
  if (!sdk.Agent.userInfo) return true;
  const { userId, useMobileCallOutType, origin } = sdk.Agent.userInfo;
  try {
    const useExtension = await getExtension({ userId, sdk });
    // 话机或手机外呼忽略
    if (useExtension || useMobileCallOutType === UseMobileCallOut.on) return true;
  } catch (error) {
    throw error;
  }

  try {
    const trtc = TRTC.create();
    await trtc.startLocalAudio({
      publish: false,
    });
    await trtc.stopLocalAudio();
    trtc.destroy();
  } catch (e) {
    // 这里做兼容处理，优先保证createLocalStream逻辑
    const mediaError = await generateGetUserMediaError(e as Error, origin);
    const logger = getLogger(sdk.Agent.userInfo);
    logger.info('generateGetUserMediaError', mediaError);
    throw mediaError;
  }
};

const isTRTCDeviceError = (error: any) => error && error.code === TRTC.ERROR_CODE.DEVICE_ERROR;

const generateGetUserMediaError = async (error: any, origin: string) => {
  const { hostname, protocol } = new URL(origin);
  if (error) {
    // https://web.sdk.qcloud.com/trtc/webrtc/v5/doc/zh-cn/module-ERROR_CODE.html#.DEVICE_ERROR
    if (error.name === 'NotFoundError' || (isTRTCDeviceError(error) && error.extraCode === 5301)) {
      Object.assign(error, { message: i18next.t('No microphone detected') });
      return error;
    }
    if (error.name === 'NotAllowedError' || (isTRTCDeviceError(error) && error.extraCode === 5302)) {
      Object.assign(error, { message: i18next.t('User denied microphone access') });
      return error;
    }
    if (error.name === 'NotReadableError' || (isTRTCDeviceError(error) && error.extraCode === 5303)) {
      Object.assign(error, { message: i18next.t('Microphones is not readable') });
      return error;
    }
  }

  /**
   * 允许获取麦克风的环境
   */
  if (['file:', 'https:'].includes(protocol) || hostname === 'localhost' || /^\d+\.\d+\.\d+\.\d+$/.test(hostname)) {
    const devices = await detect.getDevicesAsync();
    if (!devices.hasMicrophonePermission) {
      return new Error(i18next.t('User denied microphone access'));
    }
    if (!devices.microphones || devices.microphones.length === 0) {
      return new Error(i18next.t('No microphone detected'));
    }
    return error;
  }
  if (protocol === 'http:' && hostname !== 'localhost' && hostname !== '127.0.0.1') {
    // 非本地开发环境的http
    const error = new Error(
      i18next.t(
        'Due to browser restrictions, your application must be deployed with HTTPS in the production environment (localhost can be used during development), otherwise microphone permissions cannot be obtained',
      ),
    );
    error.name = 'SecurityError';

    return error;
  }

  return error;
};

export class Devices {
  sdk: TcccSdk;
  constructor(sdk: TcccSdk) {
    this.sdk = sdk;
  }

  getMicrophones() {
    return TRTC.getMicrophoneList();
  }
  getSpeakers() {
    return TRTC.getSpeakerList();
  }
  getDevices() {
    return TRTC.getMicrophoneList();
  }
  getCameras() {
    return TRTC.getCameraList();
  }
  checkDevices() {
    return checkDevice(this.sdk);
  }
  isBrowserSupported() {
    const MIN_EDGE_VERSION = 80;
    const MIN_CHROME_VERSION = 56;
    const UA = navigator.userAgent;
    const chromeMatch = UA.match(/Chrome\/([\d.]+)/);
    const edgeMatch = UA.match(/Edge\/(\d+)/i);
    const chromeVersion: number = parseInt(chromeMatch?.[1] || '', 10);
    const edgeVersion: number = parseInt(edgeMatch?.[1] || '', 10);

    return chromeVersion >= MIN_CHROME_VERSION || edgeVersion >= MIN_EDGE_VERSION;
  }
}
