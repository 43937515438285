import TRTC from 'trtc-sdk-v5';
import pick from 'lodash/pick';
import { Span, context, trace } from '@opentelemetry/api';
import Recorder from './recorder';
import { Direction } from '../constants/sessions';
import { workerInterval, clearWorkerInterval } from 'tccc-utils';
import { TcccSdk } from '../tccc';
import { Asr } from './asr/Asr';
import { ConnectionStateEnum } from '../constants/trtc';
import traceContext, { contextWithSpan } from '../http/tracer';
import { SessionType } from '../store/sessions';
import forEach from 'lodash/forEach';
import { getLogger } from '../common/Logger';
import { UserInfo } from '../tccc/Agent';
import i18next from '../i18n/i18next.config';
import { TCCCError } from '../store/createAsyncThunk';
import { createTRTC } from './memoTRTC';
import { isLocal, isSdkV2 } from 'tccc-env/src/env';

const IVR_USER = 'IVR_USER';
TRTC.setLogLevel(4);

export type TurnServerConfig = {
  url: string;
  username?: string;
  credential?: string;
  credentialType?: string;
};

function addSpanLog(name: string, attributes?: Record<string, string>) {
  return (
    target: any,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<(parentSpan: Span, ...params: any[]) => Promise<any>>,
  ) => {
    const func = descriptor.value;
    descriptor.value = async function (parentSpan: Span, ...args: any[]) {
      const userInfo = (this as WebRtc)?.emitter?.Agent?.userInfo || null;
      const span = traceContext.tracer.startSpan(
        name,
        {
          attributes: {
            ...pick(userInfo, ['sdkAppId', 'userId']),
            ...attributes,
          },
        },
        trace.setSpan(context.active(), parentSpan),
      );
      try {
        // @ts-ignore
        const result = await func.apply(this, [parentSpan, ...args]);
        span.end();
        return result;
      } catch (e) {
        const error = e as Error;
        // @ts-ignore;
        const errorCode = error.code || 0;
        span.setAttributes({
          errorName: error.name,
          errorMessage: error.message,
          errorCode,
        });
        span.recordException(error);
        span.end();
        throw e;
      }
    };
  };
}

function addTimeoutLog(message: string, timeout: number) {
  return (
    target: any,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<(...params: any[]) => Promise<any>>,
  ) => {
    const func = descriptor.value;
    descriptor.value = function (...args: any[]) {
      const logger = getLogger((this as WebRtc)?.emitter?.Agent?.userInfo || null);
      return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
          logger.debug(`${message} timeout`);
        }, timeout);
        func
          ?.apply(this, args)
          .then((e) => {
            clearTimeout(timer);
            resolve(e);
          })
          .catch((err) => {
            clearTimeout(timer);
            reject(err);
          });
      });
    };
  };
}

export const getWebRTCInstance = ({ sdk, span, sessionId }: { sdk: TcccSdk; sessionId: string; span?: Span }) => {
  if (!sdk.Agent.userInfo) {
    span?.end();
    throw new Error(i18next.t('Authorization failed'));
  }
  const { sdkAppId, userId } = sdk.Agent.userInfo;
  const key = `${sdkAppId}${userId}${sessionId}`;
  return WebRtcMap.get(key);
};

export const deleteWebRTCInstance = (sdk: TcccSdk, sessionId: string) => {
  if (!sdk.Agent.userInfo) {
    throw new Error(i18next.t('Authorization failed'));
  }
  const { sdkAppId, userId } = sdk.Agent.userInfo;
  const key = `${sdkAppId}${userId}${sessionId}`;
  WebRtcMap.delete(key);
};

export const WebRtcMap: Map<string, WebRtc> = new Map([]);
type RtcType = Exclude<SessionType, 'im'>;
export class WebRtc {
  client?: TRTC;
  userInfo: UserInfo;
  emitter: TcccSdk;
  localAudioLevelTimer?: ReturnType<typeof workerInterval>;
  recorder: Map<string, Recorder>;
  remoteUserId: string[];

  sdkAppId: string;
  userId: string;
  userSig: string;
  roomId: string;
  wasmWhiteList: boolean;
  type: RtcType;
  privateMapKey: string;
  sessionId: string;
  direction: Direction;
  finished?: boolean;
  isMuted?: boolean;

  aiEnabled: boolean;
  asr?: Asr;

  proxyServer?:
    | string
    | {
        websocketProxy: string;
        loggerProxy: string;
        unifiedProxy?: string;
      };

  logger: ReturnType<typeof getLogger>;

  onError?: (message: string) => void;

  private localVideoPlayView?: HTMLDivElement | HTMLDivElement['id'];
  // 表示是否已经播放远端流
  private remoteVideoPlayView?: HTMLDivElement | HTMLDivElement['id'];
  // private remoteVideoPlayView?: HTMLDivElement | HTMLDivElement['id'];
  private remotePlaying?: boolean;
  private remoteVideoAvailableSet = new Set<string>();
  private remoteVideoPlayingSet = new Set<string>();
  constructor({
    sdkAppId,
    userId,
    roomId,
    userSig,
    privateMapKey,
    sessionId,
    direction,
    type,
    emitter,
    onError,
    WASMWhiteList,
    proxyServer,
  }: {
    sdkAppId: string;
    userId: string;
    roomId: string;
    userSig: string;
    privateMapKey: string;
    sessionId: string;
    direction: Direction;
    type: RtcType;
    emitter: TcccSdk;
    onError?: (message: string) => void;
    WASMWhiteList: boolean;
    proxyServer?:
      | string
      | {
          websocketProxy: string;
          loggerProxy: string;
          unifiedProxy?: string;
          turnServer?: TurnServerConfig[];
        };
  }) {
    if (!emitter.Agent.userInfo) {
      throw new Error(i18next.t('Authorization failed'));
    }
    this.userInfo = emitter.Agent.userInfo;
    this.logger = getLogger(emitter.Agent.userInfo);
    this.recorder = new Map();
    this.sdkAppId = sdkAppId;
    this.userId = userId;
    this.roomId = roomId;
    this.userSig = userSig;
    this.privateMapKey = privateMapKey;
    this.sessionId = sessionId;
    this.direction = direction;
    this.emitter = emitter;
    this.type = type;
    this.wasmWhiteList = WASMWhiteList;
    this.aiEnabled = false;
    this.proxyServer = proxyServer;

    this.onError = onError;
    if (emitter && type && ['phone', 'voip'].includes(type)) {
      this.asr = new Asr({ sessionId, userId, roomId, emitter });
    }
    this.remoteUserId = [];
    // 区分多实例
    const key = `${this.userInfo.sdkAppId}${this.userInfo.userId}${sessionId}`;
    WebRtcMap.set(key, this);
  }

  async enterRoom(span: Span, inProgress?: boolean, isStartRealtimeAsr?: boolean) {
    const trtcSpan = traceContext.tracer.startSpan(
      'trtc',
      {
        attributes: {
          ...pick(this.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
          roomId: this.roomId,
          type: this.type,
          direction: this.direction,
          wasm: this.wasmWhiteList,
        },
      },
      trace.setSpan(context.active(), span),
    );
    this.aiEnabled = !!this.asr?.initAsrSocket();
    trtcSpan.setAttribute('asr', this.aiEnabled);
    await this.seatIn(trtcSpan, inProgress);
    this.client = await createTRTC({ sdkAppId: this.userInfo.sdkAppId, userId: this.userInfo.userId });
    this.initClientEvent(this.client, isStartRealtimeAsr);
    await this.joinRoom(trtcSpan, this.client);
    if (isStartRealtimeAsr) {
      try {
        const localStream = this.client.getAudioTrack();
        if (localStream) {
          this.asr?.startLocalAsr({ localStream, localUserId: this.userId });
        }
      } catch (e) {
        this.logger.error('local stream start asr error', e);
      }
    }
    trtcSpan.end();
    return Promise.resolve({
      aiEnabled: this.aiEnabled,
    });
  }
  async leaveRoom(span: Span) {
    this.logger.debug('TRTC exitRoom');
    this.finished = true;
    const trtcSpan = traceContext.tracer.startSpan(
      'trtc/leave',
      {
        attributes: pick(this.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
      },
      trace.setSpan(context.active(), span),
    );
    if (this.client) {
      this.asr?.stopLocalAsr({ localUserId: this.userId });
    }
    this.asr?.stop();
    try {
      if (this.localAudioLevelTimer) {
        trtcSpan.addEvent('clearLocalAudioLevelTimer');
        clearWorkerInterval(this.localAudioLevelTimer);
        this.localAudioLevelTimer = undefined;
      }
    } catch (e) {
      trtcSpan.recordException(e as Error);
      this.logger.warn('close audio level timer failed', e);
    }
    try {
      for (const record of this.recorder.values()) {
        record.end();
      }
    } catch (e) {
      this.logger.warn('recorder end error', e);
    }
    if (this.client) {
      this.client.off('*', () => void 0);
      this.client?.enableAudioVolumeEvaluation?.(-1);
      if (this.client) {
        try {
          await this.client.stopLocalVideo();
          trtcSpan.addEvent('stopLocalVideo');
          this.logger.info('stop local video success');
        } catch (e) {
          trtcSpan.recordException(e as Error);
          this.logger.warn('stop local video failed', e);
        }
      } else {
        trtcSpan.addEvent('emptyLocalStream');
        this.logger.warn('client unPublish failed, local stream is empty');
      }
      try {
        await this.client.stopPlugin('AIDenoiser');
      } catch (e) {
        this.logger.info('stop AIDenoiser failed', e);
      }
      try {
        await this.client.exitRoom();
        this.logger.info('client exit room success');
      } catch (e) {
        this.logger.warn('client leave failed', e);
      }
      try {
        this.client = undefined;
      } catch (e) {
        this.logger.warn('client destroy error', e);
      }
    } else {
      const log = 'client is empty';
      trtcSpan.addEvent(log);
      this.logger.warn(log);
    }
    trtcSpan.addEvent('leaveRoom success');
    this.logger.debug('TRTC exitRoom success');
    trtcSpan.end();
    return Promise.resolve();
  }
  audioVolumeChanged(event: any) {
    const streams = event.result as { volume: number; userId: string }[];
    const data = streams
      .filter((stream) => stream.userId !== IVR_USER)
      .map((stream) => ({
        audioVolume: stream.volume,
        sessionId: this.sessionId,
        userId: stream.userId === '' ? this.userInfo.userId : stream.userId,
      }));
    this.emitter?.emit('audioVolume', data);
  }
  async unmuteVideo() {
    try {
      if (this.client) {
        await this.client.updateLocalVideo({ mute: false });
        this.logger.info('unmuteVideo success');
      } else {
        this.logger.warn('unmuteVideo failed');
      }
    } catch (e) {
      this.logger.error('unmuteVideo error', e);
      throw e;
    }
  }
  async muteVideo() {
    try {
      if (this.client) {
        await this.client.updateLocalVideo({ mute: true });
        this.logger.info('muteVideo success');
      } else {
        this.logger.warn('muteVideo failed');
      }
    } catch (e) {
      this.logger.error('muteVideo error', e);
      throw e;
    }
  }
  async unmuteAudio() {
    try {
      if (this.client) {
        await this.client.updateLocalAudio({ mute: false });
        this.isMuted = false;
        this.logger.info('muteAudio success');
      } else {
        this.logger.warn('unmuteAudio failed');
      }
    } catch (e) {
      this.logger.error('unmuteAudio error', e);
      throw e;
    }
  }
  muteAudio = async () => {
    try {
      if (this.client) {
        await this.client.updateLocalAudio({ mute: true });
        this.isMuted = true;
        this.logger.info('muteAudio success');
      } else {
        this.logger.warn('muteAudio failed');
      }
    } catch (e) {
      this.logger.error('muteAudio error', e);
      throw e;
    }
  };
  onStreamPlayError(error?: MediaError) {
    const message = ['stream play error', error?.message].join(' ');
    this.logger.error(message);
    this.logger.report(message);
  }

  async startRealtimeAsr() {
    const list = [];
    if (this.client) {
      const localTrack = this.client.getAudioTrack();
      if (localTrack) {
        list.push(this.asr?.startLocalAsr({ localStream: localTrack, localUserId: this.userId }));
      }
    }
    if (!!this.remoteUserId.length) {
      forEach(this.remoteUserId, (streamUserId) => {
        if (streamUserId === IVR_USER) return;
        const remoteStream = this.client?.getAudioTrack(streamUserId);
        if (!remoteStream) return;
        list.push(this.asr?.startRemoteAsr({ remoteStream, remoteUserId: streamUserId }));
      });
    }
    await Promise.all(list);
  }

  async stopRealtimeAsr() {
    const list = [];
    if (this.client) {
      list.push(this.asr?.stopLocalAsr({ localUserId: this.userId }));
    }
    if (!!this.remoteUserId.length) {
      forEach(this.remoteUserId, (streamUserId) => {
        if (streamUserId === IVR_USER) return;
        const remoteStream = this.client?.getAudioTrack(streamUserId);
        if (!remoteStream) return;
        list.push(this.asr?.stopRemoteAsr({ remoteStream }));
      });
    }
    await Promise.all(list);
  }

  startRemoteVideo(config: Parameters<TRTC['startRemoteVideo']>[0]) {
    if (this.client) {
      if (this.remoteVideoPlayingSet.has(config.userId)) {
        return this.client.updateRemoteVideo(config);
      }
      return this.client.startRemoteVideo(config).then((res) => {
        this.remoteVideoPlayingSet.add(config.userId);
        return res;
      });
    }
    throw new Error('client not found');
  }
  playStream({ id, local }: { id: HTMLDivElement | HTMLDivElement['id']; local?: boolean; userId?: string }) {
    if (this.client) {
      if (local) {
        if (this.localVideoPlayView) {
          return this.client.updateLocalVideo({ view: id });
        }
        return this.client
          .startLocalVideo({
            view: id,
            option: {
              profile: '720p',
            },
          })
          .then((res) => {
            this.localVideoPlayView = id;
            return res;
          });
      }
      this.remoteVideoPlayView = id;
      if (this.remoteVideoAvailableSet.size !== 0) {
        return Promise.all(
          [...this.remoteVideoAvailableSet].map((item) =>
            this.startRemoteVideo({ userId: item, view: id, streamType: TRTC.TYPE.STREAM_TYPE_MAIN }),
          ),
        );
      }
    }
  }

  @addSpanLog('trtc/enter')
  @addTimeoutLog('join 5000', 5000)
  private async joinRoom(parentSpan: Span, client: TRTC) {
    const { logger } = this;
    try {
      const joinTime = Date.now();
      await client.updateLocalAudio({ publish: this.type !== 'monitor', mute: false });
      await Promise.all(
        [
          this.type !== 'monitor' &&
            this.wasmWhiteList &&
            client
              .startPlugin('AIDenoiser', {
                assetsPath:
                  isLocal && isSdkV2
                    ? location.origin
                    : `${this.emitter.Agent.origin}/${isSdkV2 ? 'sdk' : 'static/js'}`,
                sdkAppId: +this.sdkAppId,
                userId: this.userId,
                userSig: this.userSig,
              })
              .then(() => {
                logger.debug('start AI Denoise success');
              })
              .catch((e) => {
                logger.info('start AI Denoise failed', e);
              }),
          new Promise((resolve, reject) => {
            client.on(TRTC.EVENT.PUBLISH_STATE_CHANGED, function publishStateChanged(event) {
              logger.debug('publish state changed', event);
              if (event.state === 'started') {
                logger.debug('TRTC off publish event listener');
                client.off(TRTC.EVENT.PUBLISH_STATE_CHANGED, publishStateChanged);
                resolve(void 0);
              }
              if (event.state === 'stopped') {
                logger.error(`TRTC publish error${event.error}`);
                client.off(TRTC.EVENT.PUBLISH_STATE_CHANGED, publishStateChanged);
                reject(event.error);
              }
            });
            client
              .enterRoom({
                sdkAppId: +this.sdkAppId,
                userId: this.userId,
                roomId: +this.roomId,
                userSig: this.userSig,
                privateMapKey: this.privateMapKey,
                autoReceiveAudio: false,
                proxy: this.proxyServer,
              })
              .then((res) => {
                logger.info('enter room success');
                // monitor 通过enterRoom而不是publish state changed来确认是否进房成功
                if (this.type === 'monitor') return resolve(res);
              })
              .catch((err) => {
                logger.error('enter room failed', err);
                reject(err);
              });
          }),
        ].filter(Boolean),
      );
      const duration = Date.now() - joinTime;
      this.logger.info(`enter room duration: ${duration}ms`);
      parentSpan.addEvent('joinRoomSuccess', {
        duration: `${duration}`,
      });
      if (duration > 8000) {
        this.logger.report('join room duration', `${duration}ms`);
      }
    } catch (e) {
      this.logger.warn(`join failed`, e);
      if (this.finished) {
        parentSpan.setAttribute('finished', true);
        parentSpan.end();
        const error = new TCCCError(i18next.t('通话已结束'), e);
        error.code = '-9001';
        throw error;
      }
      parentSpan.recordException(e as Error);
      parentSpan.end();
      return Promise.reject(e);
    }
  }
  private async seatIn(parentSpan: Span, inProgress?: boolean) {
    if (this.direction === Direction.callIn && !inProgress) {
      const seatInSpan = traceContext.tracer.startSpan(
        'trtc/seatin',
        {
          attributes: {
            type: this.type,
            ...pick(this.emitter.Agent.userInfo, ['sdkAppId', 'userId']),
          },
        },
        trace.setSpan(context.active(), parentSpan),
      );
      if (!this.emitter.socket) {
        seatInSpan.end();
        throw new Error(i18next.t('Authorization failed'));
      }
      try {
        parentSpan.addEvent('/ccc/pstn/seatin', {
          sessionId: this.sessionId,
        });
        await contextWithSpan(seatInSpan, () => {
          if (!this.emitter.socket) {
            throw new Error(i18next.t('Authorization failed'));
          }
          return this.emitter.socket.accept({ sessionId: this.sessionId });
        });
        seatInSpan.end();
      } catch (e) {
        const error = e as Error;
        seatInSpan
          .setAttributes({
            errorName: error.name,
            errorMessage: error.message,
            errorCode: (e as any).errorCode,
          })
          .recordException(error);
        seatInSpan.end();
        parentSpan.recordException(e as Error);
        parentSpan.end();
        throw e;
      }
    }
  }
  private initClientEvent(client: TRTC, isStartRealtimeAsr?: boolean) {
    client.on(TRTC.EVENT.ERROR, (event) => {
      const errMsg = `trtc client errorCode: ${event.code}, ${event.extraCode}`;
      this.logger.report(errMsg);
      this.logger.error(errMsg);
      try {
        this.onError?.(errMsg);
      } catch (e) {
        this.logger.error('error callback exec failed', e);
      }
    });
    client.on(TRTC.EVENT.KICKED_OUT, (event) => {
      const message = ['kicked-out', `reason: ${event.reason}`].join('');
      this.logger.error(message);
      this.logger.report(message);
    });
    client.on(TRTC.EVENT.REMOTE_USER_ENTER, (event) => {
      this.logger.debug('remote user enter', event.userId);
    });
    client.on(TRTC.EVENT.REMOTE_USER_EXIT, (event) => {
      this.logger.debug('remote user exit', event.userId);
    });
    client.on(TRTC.EVENT.CONNECTION_STATE_CHANGED, async (event) => {
      this.logger.debug('connection-state-changed', `prevState: ${event.prevState} - state: ${event.state}`);
      try {
        await this.emitter.http.request('/ccc/report/seatConnectionState', {
          roomId: +this.roomId,
          connectionState: ConnectionStateEnum[event.state as any] ?? ConnectionStateEnum[ConnectionStateEnum.UNKNOWN],
        });
      } catch (e) {
        this.logger.warn('report connectionState error', e);
      }
    });
    client.on(TRTC.EVENT.NETWORK_QUALITY, async (event) => {
      this.emitter?.emit('networkQuality', event as any);
      try {
        await this.emitter.http.request('/ccc/report/seatNetworkQuality', {
          roomId: +this.roomId,
          networkQuality: event,
        });
      } catch (e) {
        this.logger.warn('report seatNetworkQuality error', e);
      }
    });
    client.on(TRTC.EVENT.AUDIO_VOLUME, (event) => this.audioVolumeChanged(event));
    client.on(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, async (event) => {
      this.logger.debug('remote audio available', event.userId);
      this.remoteUserId.push(event.userId);
      const localTrack = client.getAudioTrack();
      try {
        // 订阅对方流
        await client.muteRemoteAudio(event.userId, false);
        const isIvrUser = event.userId === IVR_USER;
        const remoteTrack = client.getAudioTrack(event.userId);
        this.logger.debug('subscribe remote audio success', Boolean(remoteTrack));
        if (remoteTrack && localTrack && !isIvrUser) {
          const recorder = new Recorder(
            remoteTrack,
            localTrack,
            {
              remoteId: event.userId,
              seatUserId: this.userInfo.userId,
              sessionId: this.sessionId,
              roomId: this.roomId,
              sdkAppId: this.userInfo.sdkAppId,
            },
            this.emitter,
          );
          recorder.start();
          this.recorder.set(event.userId, recorder);
          if (isStartRealtimeAsr) {
            this.asr?.startRemoteAsr({ remoteUserId: event.userId, remoteStream: remoteTrack });
          }
        }
      } catch (e) {
        this.logger.warn(
          'subscribe remote audio failed',
          e,
          'RTCPeerConnection' in window && 'addTransceiver' in window.RTCPeerConnection.prototype,
        );
      }
    });
    client.on(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, (event) => {
      this.logger.debug('remote audio unavailable');
      const remoteTrack = client.getAudioTrack(event.userId);
      if (remoteTrack) {
        this.asr?.stopRemoteAsr({ remoteStream: remoteTrack });
      }
      this.remoteUserId = this.remoteUserId.filter((id) => id !== event.userId);
    });
    client.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, async (event) => {
      this.logger.debug('remote video available', event.userId);
      // 有viewId直接开始
      if (this.remoteVideoPlayView) {
        await this.startRemoteVideo({
          userId: event.userId,
          streamType: TRTC.TYPE.STREAM_TYPE_MAIN,
          view: this.remoteVideoPlayView,
        });
      }
      this.remoteVideoAvailableSet.add(event.userId);
    });
    client.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, (event) => {
      this.logger.debug('remote video unavailable', event.userId);
      this.remoteVideoAvailableSet.delete(event.userId);
      this.remoteVideoPlayingSet.delete(event.userId);
    });
    client.on(TRTC.EVENT.REMOTE_USER_ENTER, (event) => {
      this.logger.debug(`remote user enter ${event.userId}`);
    });
    client.on(TRTC.EVENT.DEVICE_CHANGED, (event) => {
      this.logger.debug(`device changed ${event.type} ${event.device.label} ${event.action}`);
    });
    client.enableAudioVolumeEvaluation(500);
  }
}
