import { Span, context, trace } from '@opentelemetry/api';
import { getWebRTCInstance, WebRtc, deleteWebRTCInstance } from '../../trtc/trtc';
import { Direction, SessionType as SessionEventType } from '../../constants/sessions';
import { getOrPromiseReject } from 'tccc-utils';
import { SessionIdParams } from './sessions.thunk';
import { endCall } from './call.thunk';
import { getLogger } from '../../common/Logger';
import { SessionEnded, TcccSdk } from '../../tccc';
import { SessionType } from '.';
import traceContext from '../../http/tracer';
import { InvalidParamsError, NotFoundError } from '../../common/TcccError';
import { createAsyncThunk } from '../createAsyncThunk';
import i18next from 'i18next';

export const enterRoom = createAsyncThunk(
  'trtc/enterRoom',
  async ({
    sessionId,
    sdkAppId,
    userId,
    roomId,
    userSig,
    privateMapKey,
    direction,
    type,
    emitter,
    parentSpan,
    inProgress,
    isStartRealtimeAsr,
  }: {
    sessionId: string;
    sdkAppId: string;
    userId: string;
    roomId: string;
    userSig: string;
    privateMapKey: string;
    type: SessionType;
    direction: Direction;
    emitter: TcccSdk;
    parentSpan?: Span;
    inProgress?: boolean;
    isStartRealtimeAsr?: boolean;
  }) => {
    const span = traceContext.tracer.startSpan(
      'trtc.thunk',
      undefined,
      parentSpan && trace.setSpan(context.active(), parentSpan),
    );
    const logger = getLogger(emitter.Agent.userInfo);
    if (type === 'im') {
      logger.error('enterRoom: im session not support');
      const error = new Error(i18next.t('Failed to enter the room, im session type not supported'));
      span.recordException(error);
      span.end();
      throw error;
    }
    const { autoRealtimeAsr } = emitter.Agent.settings;
    const {
      proxy: { TRTCWebSocketProxy, TRTCLoggerProxy, TRTCTurnServer, TRTCUnifiedProxy },
    } = emitter.Proxy;
    if (getWebRTCInstance({ sdk: emitter, sessionId, span })) {
      return {
        sessionId,
        aiEnabled: false,
      };
    }
    const webRtc = new WebRtc({
      sessionId,
      sdkAppId,
      userId,
      roomId,
      userSig,
      privateMapKey,
      direction,
      type,
      emitter,
      proxyServer: {
        websocketProxy: TRTCWebSocketProxy,
        loggerProxy: TRTCLoggerProxy,
        // 如果客户配置了统一代理域名，则走 TRTC 的统一代理方案
        ...(TRTCUnifiedProxy && { unifiedProxy: TRTCUnifiedProxy }),
        turnServer: TRTCTurnServer,
      },
      onError: async (message: string) => {
        await endCall({ sessionId, closeBy: 'seat', emitter });
        const endProps: SessionEnded = {
          sessionId,
          closeBy: 'seat',
          hangupType: 211,
          mainReason: i18next.t('Client network error'),
          subReason: message,
          calleeLocation: '',
          callerLocation: '',
          direction: '0',
          duration: 0,
          id: '',
          ivrPath: [],
          protectedCallee: '',
          serverType: 'staffSeat',
          timeout: 0,
          type: SessionEventType.phone,
        };
        logger.debug('emit sessionEnded', endProps);
        emitter?.emit('sessionEnded', endProps);
      },
      WASMWhiteList:
        localStorage.getItem('wasmWhiteList') === '1' || Boolean(localStorage.getItem('enableTCCCAIDenoise')),
    });
    let aiEnabled = false;
    try {
      const res = await webRtc.enterRoom(
        span,
        inProgress,
        isStartRealtimeAsr === undefined ? autoRealtimeAsr : isStartRealtimeAsr,
      );
      aiEnabled = res.aiEnabled;
    } catch (e) {
      span.recordException(e as Error);
      await leaveRoom({ sessionId, parentSpan: span, emitter });
      throw e;
    } finally {
      span.end();
    }
    return {
      sessionId,
      aiEnabled,
    };
  },
);

export const leaveRoom = createAsyncThunk(
  'trtc/leaveRoom',
  async ({ sessionId, parentSpan, emitter }: { sessionId: string; parentSpan?: Span; emitter: TcccSdk }) => {
    const logger = getLogger(emitter.Agent.userInfo);
    const span = traceContext.tracer.startSpan(
      'trtc.thunk.leave',
      undefined,
      parentSpan && trace.setSpan(context.active(), parentSpan),
    );
    try {
      span.addEvent('findRtcItem', {
        sessionId,
      });
      const webRtc = getWebRTCInstance({ sdk: emitter, sessionId, span });
      if (!webRtc) {
        logger.debug(`cannot find webRtc instance, sessionId: ${sessionId}`);
        return span.end();
      }
      deleteWebRTCInstance(emitter, sessionId);
      await webRtc.leaveRoom(span);
    } catch (e) {
      span.recordException(e as Error);
      logger.warn('leave room failed', e);
    } finally {
      span.end();
    }
  },
);

export const muteMic = createAsyncThunk('trtc/muteMic', async (params: { sessionId: string; emitter: TcccSdk }) => {
  const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
  if (!sessionId) {
    throw new InvalidParamsError('sessionId', sessionId);
  }
  const webRtc = getWebRTCInstance({ sdk: params.emitter, sessionId });
  if (!webRtc) {
    throw new NotFoundError('session', sessionId);
  }
  try {
    webRtc.muteAudio();
  } catch (e) {
    throw e;
  }
  return {
    sessionId,
  };
});
export const unmuteMic = createAsyncThunk('trtc/unmuteMic', async (params: { sessionId: string; emitter: TcccSdk }) => {
  const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
  if (!sessionId) {
    throw new InvalidParamsError('sessionId', sessionId);
  }
  const webRtc = getWebRTCInstance({ sdk: params.emitter, sessionId });
  if (!webRtc) {
    throw new NotFoundError('session', sessionId);
  }
  try {
    webRtc.unmuteAudio();
  } catch (e) {
    throw e;
  }
  return {
    sessionId,
  };
});

export const isMicMuted = createAsyncThunk('trtc/isMisMuted', (params: { sessionId: string; sdk: TcccSdk }) => {
  const webRTC = getWebRTCInstance({ sdk: params.sdk, sessionId: params.sessionId });
  if (!webRTC) {
    throw new NotFoundError('session', params.sessionId);
  }
  return webRTC.isMuted ?? false;
});

export const muteVideo = createAsyncThunk('trtc/muteVideo', async (params: { sessionId: string; emitter: TcccSdk }) => {
  const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
  if (!sessionId) {
    throw new InvalidParamsError('sessionId', sessionId);
  }
  const webRtc = getWebRTCInstance({ sdk: params.emitter, sessionId });
  if (!webRtc) {
    throw new NotFoundError('session', sessionId);
  }
  webRtc.muteVideo();
  return {
    sessionId,
  };
});
export const unmuteVideo = createAsyncThunk(
  'trtc/unmuteVideo',
  async (params: { sessionId: string; emitter: TcccSdk }) => {
    const { sessionId } = await getOrPromiseReject(SessionIdParams)(params);
    if (!sessionId) {
      throw new InvalidParamsError('sessionId', sessionId);
    }
    const webRtc = getWebRTCInstance({ sdk: params.emitter, sessionId });
    if (!webRtc) {
      throw new NotFoundError('session', sessionId);
    }
    webRtc.unmuteVideo();
    return {
      sessionId,
    };
  },
);
