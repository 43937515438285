import { TcccSdk } from '../../tccc';
import { getLogger } from '../../common/Logger';
import TencentASRWebRecorder from './tencentASRWebRecorder';
import { TencentASRSocket } from './tencentASRSocket';
import { AsrParser, asrParserMap } from './AsrParser';
export class Asr {
  // 是否可开启asr
  enabled: boolean;

  sessionId: string;
  roomId: string;
  userId: string;
  emitter: TcccSdk;

  remoteTRTCStream: MediaStreamTrack[];

  localStreamTencentPiper?: TencentASRWebRecorder;
  remoteStreamTencentPiper: Map<string, TencentASRWebRecorder>;
  callTencentASRClientSocket?: TencentASRSocket;
  callTencentASRSeatSocket?: TencentASRSocket;
  asrParser?: Map<String, AsrParser>;
  logger: ReturnType<typeof getLogger>;

  // debug本地识别
  debugLocal?: boolean;
  constructor({
    sessionId,
    userId,
    roomId,
    emitter,
  }: {
    sessionId: string;
    userId: string;
    roomId: string;
    emitter: TcccSdk;
  }) {
    this.enabled = false;
    this.sessionId = sessionId;
    this.userId = userId;
    this.roomId = roomId;
    this.emitter = emitter;
    this.remoteStreamTencentPiper = new Map();
    this.remoteTRTCStream = [];
    this.logger = getLogger(emitter.Agent.userInfo);
    this.debugLocal = false;
  }

  /**
   * 初始化asr参数，包括获取webank token
   */
  public async initAsrSocket() {
    try {
      const { realtimeAsr } = this.emitter.Agent.settings;
      if (!realtimeAsr) {
        return;
      }
      this.enabled = true;
      return realtimeAsr;
    } catch (e) {
      this.logger.warn('get asr token failed', e);
    }
  }
  public async startLocalAsr({ localStream, localUserId }: { localStream: MediaStreamTrack; localUserId: string }) {
    if (!this.enabled) return;
    const userId = localUserId;
    try {
      const { url } = await this.emitter.http.request('/ccc/pstn/startAsr', {
        roomId: +this.roomId,
        userId,
        forceLocal: this.debugLocal,
      });
      if (!url) {
        new AsrParser({
          userId,
          side: 'seat',
          sessionId: this.sessionId,
          roomId: this.roomId,
          emitter: this.emitter,
        });
      } else {
        await this.startBackupAsr({ localStream, socketUrl: url });
      }
    } catch (e) {
      this.logger.warn('start local asr failed', e);
    }
  }

  public async stopLocalAsr({ localUserId }: { localUserId: string }) {
    if (!this.enabled) return;
    const userId = localUserId;
    try {
      await this.emitter.http.request('/ccc/pstn/stopAsr', {
        roomId: +this.roomId,
        userId,
      });
    } catch (e) {
      this.logger.warn('stopLocalAsr failed', `userId: ${userId}`, e);
    } finally {
      asrParserMap.delete(`${this.roomId}${userId}`);
    }
  }
  /**
   * stream-subscribed
   */
  public async startRemoteAsr({
    remoteStream,
    remoteUserId,
  }: {
    remoteStream: MediaStreamTrack;
    remoteUserId: string;
  }) {
    if (!this.enabled) return;
    this.remoteTRTCStream.push(remoteStream);
    const userId = remoteUserId;
    try {
      const { url } = await this.emitter.http.request('/ccc/pstn/startAsr', {
        roomId: +this.roomId,
        userId,
        forceLocal: this.debugLocal,
      });
      if (!url) {
        new AsrParser({
          userId,
          side: 'client',
          sessionId: this.sessionId,
          roomId: this.roomId,
          emitter: this.emitter,
        });
      } else {
        await this.startBackupAsr({ remoteStream, socketUrl: url });
      }
    } catch (e) {
      this.logger.warn('startAsr failed', `userId: ${userId}`, e);
    }
  }

  /**
   * trtc event stream-removed
   */
  public stopRemoteAsr({ remoteStream }: { remoteStream?: MediaStreamTrack; remoteUserId?: string }) {
    if (!this.enabled) return;
    let remoteStreamList: MediaStreamTrack[] = this.remoteTRTCStream;
    if (remoteStream) {
      remoteStreamList = [remoteStream];
      this.remoteTRTCStream = this.remoteTRTCStream.filter((stream) => stream.id !== remoteStream.id);
    }

    remoteStreamList.forEach(async (remoteStream) => {
      const userId = remoteStream.id;
      this.stopPiper({ remoteStream });
      try {
        await this.emitter.http.request('/ccc/pstn/stopAsr', {
          roomId: +this.roomId,
          userId,
        });
      } catch (e) {
        this.logger.warn('stopAsr failed', `userId: ${userId}`, e);
      } finally {
        asrParserMap.delete(`${this.roomId}${userId}`);
      }
    });
  }

  /**
   * 退房时使用
   * 关闭所有piper和websocket
   */
  public stop() {
    if (!this.enabled) return;
    try {
      this.localStreamTencentPiper?.stop();
      this.callTencentASRSeatSocket?.stop();
      this.callTencentASRClientSocket?.stop();
      this.stopRemoteAsr({});
    } catch (e) {
      this.logger.warn('close asr websocket failed', e);
    }
  }

  public checkRealtimeAsrHasBeenOpened() {
    const session = this.emitter.Call.selectOne(this.sessionId);
    return !!session?.isStartRealtimeAsr;
  }

  private stopPiper({ remoteStream }: { remoteStream: MediaStreamTrack }) {
    const { id } = remoteStream;
    const remoteStreamTencentPiper = this.remoteStreamTencentPiper.get(id);
    remoteStreamTencentPiper?.stop();
  }

  /**
   * 本地、远端流共用backup asr方法
   * 每个流都是独立的
   */
  private async startBackupAsr(
    params: ({ localStream: MediaStreamTrack } | { remoteStream: MediaStreamTrack }) & { socketUrl: string },
  ) {
    try {
      if (!this.callTencentASRSeatSocket && !this.localStreamTencentPiper && 'localStream' in params) {
        this.callTencentASRSeatSocket = new TencentASRSocket({
          sessionId: this.sessionId,
          roomId: this.roomId,
          userId: this.userId,
          side: 'seat',
          emitter: this.emitter,
        });
        this.callTencentASRSeatSocket.start(params.socketUrl);
        this.localStreamTencentPiper = new TencentASRWebRecorder(params.localStream, this.callTencentASRSeatSocket);
        this.localStreamTencentPiper.start();
      }
      if (!this.callTencentASRClientSocket && 'remoteStream' in params) {
        this.callTencentASRClientSocket = new TencentASRSocket({
          sessionId: this.sessionId,
          userId: this.userId,
          roomId: this.roomId,
          side: 'client',
          emitter: this.emitter,
        });
        this.callTencentASRClientSocket.start(params.socketUrl);
        const curRemoteStreamTencentPiper = new TencentASRWebRecorder(
          params.remoteStream,
          this.callTencentASRClientSocket,
        );
        const { id } = params.remoteStream;
        curRemoteStreamTencentPiper.start();
        this.remoteStreamTencentPiper.set(id, curRemoteStreamTencentPiper);
      }
    } catch (e) {
      this.logger.error('trigger piper failed', e);
    }
  }
}
